import React, { useEffect, useRef, useState } from "react";
import "./MealItem.scss";
import products from "../../../../../data/new_products.json";

import { DietPlan } from "../DietsCreatorPage";
import {
  AvocadoIcon,
  OilIcon,
  FishIcon,
  PotatoIcon,
  MeatIcon,
  RiceIcon,
} from "../../../../../assets/images/svg/Icons";
import NiceSelect from "../../../../../components/NiceSelect/NiceSelect";

interface IMealItem {
  meal: number;
  day: number;
  carbsPerMeal: number;
  proteinsPerMeal: number;
  fatsPerMeal: number;
  setDietPlan: React.Dispatch<React.SetStateAction<DietPlan>>;
}

const MealItem: React.FC<IMealItem> = ({
  meal,
  day,
  carbsPerMeal,
  fatsPerMeal,
  proteinsPerMeal,
  setDietPlan,
}) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const protein = products.filter((item) => item.category === "bialko");
  const carbs = products.filter((item) => item.category === "wegiel");
  const fats = products.filter((item) => item.category === "tluszcz");

  const [mealsNutritions, setMealsNutritions] = useState({
    fat: "",
    carb: "",
    protein: "",
  });

  useEffect(() => {
    setDietPlan((prevDietPlan) => {
      let newDietPlan = JSON.parse(JSON.stringify(prevDietPlan));

      newDietPlan.days[day].meals[meal] =
        Object.values(mealsNutritions).join(", ");

      return newDietPlan;
    });
  }, [mealsNutritions, day, meal, setDietPlan]);

  const handleChangeSelect = (e: any, type: "fat" | "carb" | "protein") => {
    setMealsNutritions((prev) => {
      const newValue = { ...prev, [type]: e.target.value };

      return newValue;
    });
  };

  useEffect(() => {
    // Inicjalizacja NiceSelect
    if (selectRef.current) {
      NiceSelect.bind(selectRef.current);
    }

    return () => {
      // Opcjonalne czyszczenie przy odmontowywaniu
      // Zależne od implementacji NiceSelect
    };
  }, []);

  useEffect(() => {
    // Aktualizacja NiceSelect przy zmianie wartości

    NiceSelect.bind(selectRef.current); // Może być konieczne ponowne zastosowanie NiceSelect
  }, [proteinsPerMeal]);

  return (
    <div className="meal_wrapper">
      <span className="meal_header">Posiłek {meal}</span>
      <div className="meal_body">
        <div className="nutrition_wr">
          <div className="nutrition__header">
            <AvocadoIcon />
            <OilIcon />
            <span>tłuszcz</span>
            <span>{fatsPerMeal}g</span>
          </div>
          <NiceSelect
            id={`${meal}-${day}-fat`}
            className="meal_select"
            placeholder="Wybierz"
            ref={selectRef}
            onChange={(e: any) => handleChangeSelect(e, "fat")}
          >
            {fats.map((item, idx) => {
              if (item.Produkt === "żółte jaja kurze") {
                const product = `${item.Produkt} ${(
                  fatsPerMeal / item["Tłuszcze[g]"]
                ).toFixed()} szt.`;

                return (
                  <option key={idx} value={product}>
                    {product}
                  </option>
                );
              }
              const product = `${item.Produkt} (${(
                (fatsPerMeal * 100) /
                item["Tłuszcze[g]"]
              ).toFixed()}g)`;

              return (
                <option key={idx} value={product}>
                  {product}
                </option>
              );
            })}
          </NiceSelect>
        </div>
        <div className="nutrition_wr">
          <div className="nutrition__header">
            <FishIcon />
            <MeatIcon />
            <span>białko</span>
            <span>{proteinsPerMeal}g</span>
          </div>
          <NiceSelect
            id={`${meal}-${day}-protein`}
            className="meal_select"
            placeholder="Wybierz"
            onChange={(e: any) => handleChangeSelect(e, "protein")}
          >
            {protein.map((item, idx) => {
              if (item.Produkt === "białko jaja kurzego") {
                const mlPerProtein = (proteinsPerMeal * 100) / 10;
                const product = `${item.Produkt} (${mlPerProtein.toFixed()} ml)`;


                return (
                  <option key={idx} value={product}>
                    {product}
                  </option>
                );
              }

              const product = `${item.Produkt} (${(
                (proteinsPerMeal * 100) /
                item["Białka[g]"]
              ).toFixed()}g)`;

              return (
                <option className="option" key={idx} value={product}>
                  {product}
                </option>
              );
            })}
          </NiceSelect>
        </div>
        <div className="nutrition_wr">
          <div className="nutrition__header">
            <PotatoIcon />
            <RiceIcon />
            <span>węglowodany</span>
            <span>{carbsPerMeal}g</span>
          </div>
          <NiceSelect
            id={`${meal}-${day}-carb`}
            className="meal_select"
            placeholder="Wybierz"
            onChange={(e: any) => handleChangeSelect(e, "carb")}
          >
            {carbs.map((item, idx) => {
              const product = `${item.Produkt} (${(
                (carbsPerMeal * 100) /
                item["Węglowodany[g]"]
              ).toFixed()}g)`;

              return (
                <option key={idx} value={product}>
                  {product}
                </option>
              );
            })}
          </NiceSelect>
        </div>
      </div>
    </div>
  );
};

export default MealItem;
